<template>
  <div class="w-full mb-12 md:mb-40 p-3">
    <div class="w-full flex flex-col-reverse md:grid md:grid-cols-7 p-2 gap-4  rounded-md border-white/20 border ">
    
    <div class="text-center md:col-span-3 w-full flex justify-center items-center md:pl-8 pt-8">
        <div class="w-full sm:text-left text-center" >
          <!-- <h4 class="text-4xl text-white font-brand font-bold mb-2">Only 2 Steps away</h4> -->
          <div class="md:mb-8 mb-2 text-4xl md:text-5xl font-brand font-bold text-white ">
            <div>Sign Up<Icon class="" name="material-symbols:arrow-right-alt"> </Icon>Stake</div>
          </div>
          <p class="md:text-xl tetx-lg font-light text-zinc-100 pl-1">Join Oxbull in less than <span class="px-2 py-1 mx-1 font-brand font-bold bg-white text-black rounded-md">2 minutes</span> </p>
          <p class="md:pt-16 pt-4 pb-4 md:pb-0 pl-1 text-zinc-500 font-light mb-8">Here's a quick video walkthrough.</p>


          <a id="stake_stake" href="/app/stake" target="_blank" class="sm:w-[180px] btn bg-white text-black text-lg hover:text-white hover:bg-white/20 font-brand font-bold border-none">Stake Now</a>
        </div>
      </div>

      <div class="w-full flex justify-center items-center col-span-4">
        <div class="w-full aspect-video bg-white/10 rounded-md border border-white/20">
          <video class="w-full h-full object-cover rounded-md" src="/oxbull_stake.webm" autoplay loop muted controls playsinline ></video>
        </div>
      </div>
  </div>
  </div>
</template>

<script lang="ts" setup>

</script>

<style>

</style>